<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab">
            <a-icon type="project" /> {{ $t("TableContent") }}
          </span>
          <!-- lang tabs -->
          <a-row
            align="middle"
            class="flex-baseline"
            style="margin-bottom: 15px"
          >
            <a-col :span="6" style="text-align: right; padding-right: 8px">
              {{ $t("Lang") }}:
            </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="6" style="text-align: right; padding-right: 8px">
              Title:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input v-model="form['title_' + getKey]" placeholder="Title" />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="6" style="text-align: right; padding-right: 8px">
              Boss:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-select
                v-model="form.boss"
                style="width: 100%"
                :filter-option="filterOption"
                show-search
                option-filter-prop="children"
              >
                <a-select-option
                  v-for="(staff, index) in staffList"
                  :key="index"
                  :value="staff.id"
                >
                  {{ staff && staff.full_name }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="6" style="text-align: right; padding-right: 8px">
              Staff:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-select
                v-model="form.staffs"
                mode="multiple"
                style="width: 100%"
                :filter-option="filterOption"
                show-search
                option-filter-prop="children"
              >
                <a-select-option
                  v-for="(staff, index) in staffList"
                  :key="index"
                  :value="staff.id"
                >
                  {{ staff.full_name }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="6" style="text-align: right; padding-right: 8px">
              Region:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-select
                v-model="form.region"
                style="width: 100%"
                :filter-option="filterOption"
                show-search
                option-filter-prop="children"
              >
                <a-select-option
                  v-for="(region, index) in regionList"
                  :key="index"
                  :value="region.id"
                >
                  {{ region.title }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="6" style="text-align: right; padding-right: 8px">
              Address:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input
                v-model="form['address_' + getKey]"
                placeholder="Address"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="6" style="text-align: right; padding-right: 8px">
              Order:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input v-model="form.order" type="number" placeholder="Order" />
            </a-col>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="6" />
            <a-col :span="12">
              <a-row>
                <a-button style="margin-right: 10px" @click="$router.go(-1)">
                  <a-icon type="close" />{{ $t("Cancel") }}
                </a-button>
                <a-button
                  :loading="pending"
                  type="primary"
                  @click="submitManagment"
                >
                  {{ $t("Save") }}
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      form: {
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        address_uz: "",
        address_ru: "",
        address_en: "",
        address_oz: "",
        boss: null,
        order: null,
        staffs: [],
        region: null
      },
      langTab: "oz",
      staffList: [],
      pending: false,
      regionList: []
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  async mounted() {
    this.staffList = await this.$store.dispatch("control/fetch")

    this.regionList = await this.$store.dispatch("region/fetchRegionList")
  },
  async created() {
    const form = await this.$store.dispatch(
      "department/detail",
      this.$route.params.id
    )
    this.form = form
    this.form.staffs = form.staffs.map((el) => el.id)
    console.log("form", this.form)
    this.form.boss = form.boss.id
  },
  methods: {
    async submitManagment() {
      this.pending = true
      const id = this.$route.params.id
      try {
        this.$api.patch(`/admin/structure/department/${id}/update/`, this.form)
        this.$message.success("Менеджмент успешно изменен!")
        this.$router.go(-1)
      } catch (error) {
        console.error(error)
        this.$sentry.captureMessage(error)
      }
      this.pending = false
    }
  }
}
</script>

<style></style>
